<template>
  <div class="public-body">
    <div class="public-body__block public-body__block--decor">
      <img src="@/assets/img/mkp-logo.svg" alt="logo" />
    </div>
    <div class="public-body__block">
      <div class="public-body__title public-body__title--center title">СОГЛАСИЕ</div>
      <div class="public-body__subt public-body__subt--center">на обработку персональных данных при использовании Автоматизированной информационной системы «Международный клуб привилегий Российского Союза Молодежи» Общероссийской общественной организации «Российский Союз Молодежи»</div>
      <p class="public-body__paragraph">Настоящим я (далее – субъект персональных данных) во исполнение требований Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» (с изменениями и дополнениями) свободно, своей волей и в своем интересе принял(а) решение о предоставлении своих персональных данных Общероссийской общественной организации «Российский Союз Молодёжи», адрес: г. Москва, ул. Маросейка, д. 3/13 (далее – РСМ), и даю свое согласие на обработку своих персональных данных, указанных на форме регистрации на сайте mkp.ruy.ru (далее – Сайт).</p>
      <p class="public-body__paragraph">Согласие распространяется на следующую информацию: фамилия, имя, отчество, дата рождения, телефон, электронная почта, фото, адреса места жительства, паспортные данные, ссылки на страницы в социальных сетях.</p>
      <p class="public-body__paragraph">Согласие предоставляется на обработку, т.е. осуществление следующих действий в отношении моих персональных данных: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, включая СМИ, размещение на Сайте, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных в соответствии с законодательством Российской Федерации.</p>
      <p class="public-body__paragraph">Персональные данные предоставляются в целях организации и обеспечения участия в проекте «Международный клуб привилегий Российского Союза Молодежи», оформления карты МКП РСМ & EYCA и исполнения требований, установленных законодательством Российской Федерации.</p>
      <p class="public-body__paragraph">Я согласен с тем, что сбор и обработка моих персональных данных осуществляется в целях регистрации и предоставления доступа к функционалу Сайта, предоставления возможности участия в программах и проектах РСМ, направления на предоставленные мной контактные данные сообщений и смс-уведомлений, в том числе информационных и новостных рассылок, опросов, тестирований.</p>
      <p class="public-body__paragraph">Я согласен на обработку своих персональных данных и их передачу третьим лицам в целях обеспечения их защищенного хранения, защищенного доступа к ним и функционирования Сайта.</p>
      <p class="public-body__paragraph">Датой выдачи настоящего согласия на обработку моих персональных данных является дата моей регистрации на Сайте.</p>
      <p class="public-body__paragraph">Я ознакомлен с документами РСМ, устанавливающими порядок обработки персональных данных, а также с моими правами и обязанностями в этой сфере.</p>
      <p class="public-body__paragraph">Я ознакомлен(а) с тем, что:</p>
      <p class="public-body__paragraph">- настоящее согласие на обработку моих персональных данных, указанных в форме регистрации на Сайте, направляемых (заполненных) с использованием Сайта, действует до дня его отзыва в письменной форме;</p>
      <p class="public-body__paragraph">- согласие может быть отозвано мною на основании письменного заявления в бумажном виде, направляемого почтовым отправлением по адресу: г. Москва, ул. Маросейка, д. 3/13, Общероссийская общественная организация «Российский Союз Молодёжи», или в виде отсканированной копии, направляемой по электронной почте на адрес
        <a href="mailto:webmaster@ruy.ru">webmaster@ruy.ru</a>.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacy'
};
</script>
